
export const subtotalGeral = cart => {
    return cart.reduce((a, b) => a + (b.premio ? 0 : +subtotal(b)), 0);
};

export const valorFrete = pedido => {
    return pedido.frete_gratis ? 0 : +pedido.valor_frete;
};

export const total = (cart, valorDesconto = 0) => {
    let total = subtotalGeral(cart);
    total -= +valorDesconto || 0;
    return total.toFixed(2);
};

export const subtotal = item => {
    let valorComplementos = 0;

    // Combo
    if (item.produtos?.length) {
        valorComplementos = +(item.valor || 0);
        item.produtos.forEach(produto => {
            produto.passos?.forEach(passo => {
                valorComplementos += valorTotalComplementos(passo);
            })
        });
        return (valorComplementos * item.quantidade).toFixed(2);
    }

    item?.passos?.forEach(passo => (valorComplementos += valorTotalComplementos(passo)));

    return ((+item.valor + valorComplementos) * item.quantidade).toFixed(2);
};

export const valorTotalComplementos = passo => {
    if (passo?.forma_calculo == 'Média') {
        const quantidade = passo?.complementos?.reduce((a, b) => a + (+b.quantidade || 0), 0) || 1;
        const valor = passo?.complementos?.reduce((a, b) => a + (+b.quantidade || 0) * +b.valor, 0) || 0;

        return valor ? (valor / quantidade) : 0;
    }

    if (passo?.forma_calculo == 'Maior valor') {
        let value = 0;
        passo?.complementos?.forEach(a => {
            let bigger = +a.quantidade ? +a.valor : 0;
            (bigger > value) && (value = bigger);
        })
        return value;
    }

    // Soma
    return passo?.complementos?.reduce((a, b) => a + (+b.quantidade || 0) * +b.valor, 0) || 0;
};

export const fracao = (passo, adicional) => {
    const total = passo.complementos.reduce((a, b) => a + (+b.quantidade || 0), 0);
    return passo.formato_quantidade === 'Fração' ? `${adicional.quantidade}/${total}` : `${adicional.quantidade}x`;
};
