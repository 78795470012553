<template>
    <v-dialog v-model="visible" persistent max-width="600px">
        <v-card>
            <v-card-title class="justify-center">
                <v-spacer/>
                <span class="text-h5">Selecione o Entregador</span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text align="center" justify="center" style="height: 120px;">
                <v-autocomplete
                    prepend-inner-icon="mdi-magnify"
                    v-model="entregador"
                    :items="dados"
                    return-object
                    clearable
                    item-text="nome"
                    item-value="id"
                    label="Entregador"
                    outlined
                    dense
                    hide-details
                />
                <v-checkbox
                    v-model="enviarWhatsapp"
                    label="Enviar pedido no whatsapp do entregador"
                />
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer />
                <v-btn color="success" @click="salvar" :loading="loading">
                    <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'EntregadorSelectDialog',

    props: ['value', 'visible'],

    data: () => ({
        loading: false,
        entregador: null,
        enviarWhatsapp: true,
        dados: [],
    }),

    mounted() {
        this.consultar();

        const enviarWhatsapp = localStorage.getItem('enviarWhatsappEntregador');
        if (enviarWhatsapp !== null) {
            this.enviarWhatsapp = enviarWhatsapp === 'true';
        }
    },

    methods: {
        consultar() {
            this.dados = [];
            this.$http.get('entregadores').then(resp => {
                this.dados = resp.data.data;
                if (this.dados.length === 1) {
                    this.entregador = this.dados[0];
                }
                this.loading = false;
            })
            .catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            });
        },

        salvar() {
            if (!this.entregador?.id) {
                this.notify('Selecione o entregador', 'warning');
                return;
            }

            localStorage.setItem('enviarWhatsappEntregador', this.enviarWhatsapp.toString());

            const params = `?enviarWhatsapp=${this.enviarWhatsapp}`;

            this.loading = true;
            this.$http.put(`pedidos/${this.value}/update-entregador/${this.entregador.id}${params}`).then(() => {
                this.notify('Atualizado com sucesso!');
                this.$emit('close');
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        },
    },
}
</script>
