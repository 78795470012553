<template>
    <v-dialog v-model="visible" fullscreen>
        <v-card>
            <v-card-title>
                <v-btn icon @click="onClickGoBack()">
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>
                <v-spacer />
                <span style="text-transform: uppercase;">
                    {{ comboTitle }}<b>{{ productTitle }}</b>
                </span>
                <v-spacer />
            </v-card-title>
            <v-card-text>
                <v-progress-linear v-model="progress" height="15" color="primary">
                    <strong>{{ getProgress() }}</strong>
                </v-progress-linear>

                <div :style="`margin-top: 5px; padding: 5px; width: 100%; z-index: 2; background-color: ${isDark ? '#111' : '#fff'}`">
                    <div class="flex-container" style="text-align: center; font-size: 20px; font-weight: bold;">
                        {{ getTitle() }}
                    </div>
                    <div style="display: flex; justify-content: center; text-align: center;">
                        <div class="mr-2">Minimo: {{ getStepQtd('min') }}</div>
                        <div class="ml-2">Máximo: {{ getStepQtd('max') }}</div>
                    </div>
                    <div style="text-align: center;">
                        Valor Adicional: R$ {{ getValorTotalComplementos().toFixed(2) }}
                    </div>
                    <div v-if="showCategorias" style="display: flex; align-items: center; width: 100%; height: 35px; z-index: 99;">
                        <v-btn icon class="mt-1" small>
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <div style="overflow-x: auto; white-space: nowrap;  width: 100%">
                            <v-btn
                                v-for="categoria in categorias()" :key="categoria"
                                color="primary"
                                class="mr-1"
                                min-width="100px"
                                @click="categoriaFilter = categoria"
                                depressed
                                tile
                                small
                            >
                                {{ categoria }}
                            </v-btn>
                        </div>
                        <v-btn icon class="mt-1">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                    </div>
                </div>

                <div>
                    <v-text-field
                        v-if="showPesquisa"
                        v-model="search"
                        dense
                        outlined
                        clearable
                        hide-details
                        label="Digite para pesquisar..."
                        prepend-inner-icon="mdi-magnify"
                    />
                </div>

                <div
                    ref="complementsContainer"
                    :style="`height: ${getListHeight}px; overflow-x: auto;`"
                >
                    <v-card
                        class="mx-auto my-2"
                        width="600"
                        elevation="8"
                        outlined
                        tile
                        v-for="complement in complementsFiltered()"
                        :key="complement.index"
                        :style="
                            complement.quantidade > 0
                                ? `border-radius: 5px; border: solid 3px ${isDark ? '#fff' : '#666'}`
                                : ''
                        "
                        @click="maxLimit(complement.index) && onClickAddComplement(complement.index)"
                    >
                        <div style="display: flex; justify-content: space-between; padding: 20px;">
                            <div>
                                <div class="product-title" :style="`color: ${isDark ? '#fff' : '#111'}`">
                                    {{complement.descricao}}
                                </div>
                                <div class="product-details" :style="`color: ${isDark ? '#d2d2d2' : '#666'}`">
                                    {{complement.detalhes}}
                                </div>
                                <v-chip
                                    v-if="+complement.valor"
                                    small
                                    outlined
                                    color="#999"
                                    :text-color="isDark ? '#fff' : '#444'"
                                >+ R$ {{(+complement.valor).toFixed(2)}}</v-chip>

                                <v-chip
                                    v-if="isIndisponivel(complement)"
                                    small
                                    color="error"
                                    text-color="white"
                                    class="ml-2"
                                >Indisponível</v-chip>
                            </div>
                            <div style="min-width: 85px">
                                <v-btn
                                    icon
                                    :disabled="(complement.quantidade || 0) <= 0"
                                    @click.stop="onClickDelComplement(complement.index)"
                                >
                                    <v-icon>mdi-minus</v-icon>
                                </v-btn>
                                <b>{{ complement.quantidade || 0 }}</b>
                                <v-btn
                                    icon
                                    :disabled="!maxLimit(complement.index)"
                                    @click.stop="onClickAddComplement(complement.index)"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </div>
                        </div>
                    </v-card>
                </div>
                <v-btn
                    color="primary"
                    large
                    block
                    class="white--text mt-2"
                    @click="onClickNext()"
                >Continuar</v-btn>
            </v-card-text>
        </v-card>

        <v-dialog v-model="dialog.show" persistent max-width="300">
            <v-card>
                <v-card-title class="headline justify-center" style="word-break: break-word;">
                    {{ dialog.text }}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" style="color: white;" @click="dialogOk()" block>Ok</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-dialog>
</template>

<script>
import { valorTotalComplementos } from '@/utils/calc'
import { clone } from '@/utils/utils'

export default {
    name: 'Steps',

    props: {
        visible: {
            type: Boolean,
            default: false
        },

        value: {
            type: Object,
        },
    },

    data: () => ({
        search: '',
        storedProduct: null,
        currentProductIndex: null,
        currentStepIndex: 0,
        progress: 0,
        steps: [],
        categoriaFilter: '',
        dialog: {
            text: '',
            show: false,
        }
    }),

    watch: {
        currentProduct: function () {
            if (!this.combo) {
                return;
            }

            this.dialog = {
                text: 'Escolha as opções para ' + this.productTitle,
                show: true
            }
        },
        deep: true,

        visible(v) {
            v && this.initialize();

            !v && this.$emit('close');
        },
    },

    computed: {
        isDark() {
            return this.$vuetify.theme.isDark;
        },

        classMenu() {
            return this.isDark ? 'app-bar-dark' : 'app-bar-light';
        },

        cssVars() {
            return {
                '--bg-color': '#000',
                'padding': '0 0 10px 0'
            }
        },

        currentProduct() {
            const currentProduct = this.storedProduct;

            if (currentProduct?.produtos?.length) {
                return currentProduct.produtos[this.currentProductIndex];
            }
            return currentProduct;
        },

        currentStep() {
            return this.currentProduct?.passos[this.currentStepIndex];
        },

        productTitle() {
            return this.currentProduct?.descricao || '';
        },

        comboTitle() {
            return this.storedProduct?.produtos?.length ? (this.storedProduct.descricao + ' / ') : '';
        },

        combo() {
            return this.storedProduct?.produtos?.length ? this.storedProduct.produtos : null;
        },

        showPesquisa() {
            const complementos = this.complements();
            return complementos.length > 10;
        },

        showCategorias() {
            return this.categorias().length > 1
        },

        getListHeight() {
            let offset = 230;
            offset += this.showPesquisa ? 40 : 0;
            offset += this.showCategorias ? 40 : 0;

            return this.$vuetify.breakpoint.height - offset;
        },
    },

    methods: {
        async initialize() {
            this.storedProduct = clone(this.value);

            this.storedProduct?.produtos?.length &&
                (this.currentProductIndex = this.storedProduct.produtos.findIndex(c => c.passos?.length));

            this.fillSteps();

            this.steps = this.currentProduct?.passos;
        },

        fillSteps() {
            this.currentProduct.passos.forEach(s => {
                s.complementos.forEach(a => {
                    a.quantidade = 0;
                });
            });

            this.$forceUpdate();
        },

        getValorTotalComplementos() {
            return valorTotalComplementos(this.currentStep);
        },

        complements() {
            return this.currentProduct?.passos[this.currentStepIndex]?.complementos || [];
        },

        complementsFiltered() {
            const complements = clone(this.complements());
            complements.forEach((c, index) => (c.index = index));

            if (this.search) {
                return complements.filter(c =>
                    c.descricao.trim().toLowerCase().includes(this.search.trim().toLowerCase())
                );
            }

            return !this.categoriaFilter
                ? complements
                : complements.filter(c => c.categoria.descricao == this.categoriaFilter);
        },

        categorias() {
            const complementos = this.complements(),
                uniques = [];

            complementos.forEach(c => !uniques.includes(c.categoria.descricao) && uniques.push(c.categoria.descricao));

            return uniques;
        },

        maxLimit(index) {
            const qtdMaxGeral = this.getStepQtd('max');
            const qtdTotalGeral = this.currentStep.complementos.reduce((a, b) => a + (+b.quantidade || 0), 0);

            const qtdAtual = this.currentStep.complementos[index].quantidade || 0;
            const qtdMax = this.currentStep.complementos[index].max || qtdMaxGeral;

            if (qtdAtual >= qtdMax) {
                // this.notify('Quantidade maxima permitda ' + qtdMax, 'warning');
                return false;
            }

            if (qtdTotalGeral >= qtdMaxGeral) {
                // this.notify('Quantidade maxima permitda ' + qtdMaxGeral, 'warning');
                return false;
            }

            return true;
        },

        isIndisponivel(complement) {
            const outOfStock = complement.controle_estoque && complement.estoque <= 0;
            return (complement.status == 'inativo') || outOfStock;
        },

        async onClickAddComplement(index) {
            const comp = this.currentStep.complementos[index];

            const isIndisponivel = this.isIndisponivel(comp);
            const msg = 'Item indisponível, deseja adicionar mesmo assim?';
            if (isIndisponivel && !await this.$root.$confirm('Confirmação', msg, { color: 'red darken-1' })) {
                return;
            }

            !comp.quantidade && (comp.quantidade = 0);

            comp.quantidade++;
            this.$forceUpdate();
        },

        onClickDelComplement(index) {
            const qtdAtual = this.currentStep.complementos[index].quantidade;
            // const qtdMin = this.currentStep.complementos[index].min;

            // if (qtdAtual <= qtdMin) {
            //     this.notify('Quantidade minima permitda ' + qtdMin, 'warning');
            //     return;
            // }

            if (qtdAtual <= 0) {
                return;
            }

            this.currentStep.complementos[index].quantidade--;
            this.$forceUpdate();
        },

        scrollTop() {
            this.$refs.complementsContainer.scrollTop = 0;
        },

        onClickNext() {
            const stepsLength = (this.currentProduct?.passos || []).length - 1;
            const qtdComplementos = this.currentStep.complementos.reduce((a, b) => a + (+b.quantidade || 0), 0);
            const qtdMinima = +this.currentStep.min;
            const complementoAbaixoMin = this.currentStep.complementos
                .find(c => +c.quantidade !== 0 && +c.quantidade < +c.min);

            if (qtdMinima && qtdComplementos < qtdMinima) {
                this.notify(`Escolha no mínimo ${qtdMinima}`, 'warning');
                return;
            }

            if (complementoAbaixoMin) {
                this.notify(
                    `Quantidade minima ${complementoAbaixoMin.min} para ${complementoAbaixoMin.descricao}`,
                    'warning'
                );
                return;
            }

            this.search = '';
            this.categoriaFilter = '';

            if (this.currentStepIndex < stepsLength) {
                this.currentStepIndex++;
                this.scrollTop();
                return;
            }

            //Remove passos e complementos zerados
            this.currentProduct.passos.forEach((s, index) => {
                let empty = !s.complementos.reduce((a, b) => a + +(b.quantidade || 0), 0);
                if (empty) {
                    delete this.currentProduct.passos[index];
                }

                if (this.currentProduct.passos[index]) {
                    s.complementos = s.complementos.filter(a => +(a.quantidade || 0) > 0);
                }
            });

            this.currentProduct.passos = this.currentProduct.passos.filter(n => n)

            const combo = this.combo;
            if (combo) {
                this.storedProduct.produtos[this.currentProductIndex] = clone(this.currentProduct);

                //Proximo produto do combo que contem passos
                const index = combo.findIndex((c, index) =>
                    c.passos?.length && index > this.currentProductIndex) || -1;

                if (index !== -1) {
                    this.currentProductIndex = index;
                    this.currentStepIndex = 0;
                    this.fillSteps();
                    return;
                }
            }

            this.currentStepIndex = 0;

            this.$emit('finish', this.combo ? this.storedProduct : this.currentProduct)
        },

        onClickGoBack() {
            if (this.currentStepIndex === 0) {
                this.$emit('close');
                return;
            }

            this.search = '';
            this.categoriaFilter = '';
            this.currentStepIndex--;
        },

        dialogOk() {
            this.dialog = {
                text: '',
                show: false
            };
        },

        getStepQtd(param) {
            if (param === 'min') {
                return this.currentStep?.min || 0;
            }

            return this.currentStep?.max || 20;
        },

        getProgress() {
            const stepsLength = (this.currentProduct?.passos || []).length;
            const progress = this.currentStepIndex + 1;
            this.progress = 100 / stepsLength * progress;

            return `${progress}/${stepsLength}`;
        },

        getTitle() {
            return this.currentStep?.titulo || '';
        },
    }
}
</script>

<style scoped>
.app-bar-dark {
    background-color: var(--bg-color) !important;
}

.app-bar-light {
    background-color: var(--bg-color) !important;
}

.product-title {
    text-transform: uppercase;
    -webkit-text-size-adjust: 100%;
    line-height: 1.2;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
}

.product-details {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    line-height: 20px;
    padding: 2px 0 2px 0;
}
</style>
