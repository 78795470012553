<template>
    <v-card
        class="mx-auto my-4"
        width="160"
        min-height="70"
        elevation="8"
        outlined
        dark
        @click="$emit('onPedidoClick', pedido)"
        :style="'background-color: ' + cardColor(pedido.entrega)"
    >
        <div v-if="pedido.status === 'recebido'" class="card-overlay">
            <div class="card-overlay-center">
                <v-btn icon width="60" height="60">
                    <v-icon x-large>mdi-eye</v-icon>
                </v-btn>
            </div>
            <div class="px-1">
                <v-btn
                    @click.stop="$emit('onUpdateStatus', {id: pedido.id, status: 'confirmado'})"
                    block
                    rounded
                    color="green"
                >
                    Aceitar
                </v-btn>
            </div>
            <div class="mt-2 px-4">
                <v-btn
                    @click.stop="$emit('onUpdateStatus', {id: pedido.id, status: 'cancelado'})"
                    small
                    block
                    rounded
                    color="red"
                >
                    Recusar
                </v-btn>
            </div>
        </div>

        <div
            v-if="pedido.agendado"
            class="pt-1 mb-1"
            style="font-size: 15px; font-weight: bold; text-align: center; background-color: #000;"
        >
            <span>Agendado</span>
            <br>
            <span :style="`font-size: ${pedido.agendado_datahora?.length > 20 ? '12' : '15'}px;`">
                {{ agendamentoFormat(pedido.agendado_datahora) }}
            </span>
            <v-divider/>
        </div>
        <v-card-title class="justify-center mt-1">
            PEDIDO #{{ pedido.id }}
        </v-card-title>
        <v-card-title>
            <v-icon>mdi-account</v-icon>
            {{ getClienteNome }}
        </v-card-title>
        <v-card-title v-if="pedido.entrega == 3">
            <v-icon class="mr-1">mdi-table-furniture</v-icon>
            {{ pedido.mesa }}
            <v-icon style="margin: 0 6px 0 24px">mdi-account-group</v-icon>
            {{ pedido.pessoas }}
        </v-card-title>
        <v-card-title class="mt-1" style="text-transform: uppercase; font-size: 13px;">
            {{ getEntrega(pedido.entrega) }}
        </v-card-title>
        <v-card-title v-if="pedido.prazo_status" class="justify-center">
            <span :class="{pulse: pedido.prazo_status.atrasado}">
                {{ pedido.prazo_status.text }}
            </span>
        </v-card-title>
        <v-progress-linear
            v-if="pedido.prazo_status"
            :value="pedido.progresso"
            class="mx-4"
            :color="getProgressBarColor(pedido)"
            rounded
            style="width: 125px;"
        />
        <v-card-title v-if="pedido.prazo_finalizacao" class="mx-2">
            <span>{{ timeFormat(pedido.created_at) }}</span>
            <v-divider v-if="pedido.prazo_finalizacao" class="mx-1"/>
            <span v-if="pedido.prazo_finalizacao">
                {{ timeFormat(pedido.prazo_finalizacao) }}
            </span>
        </v-card-title>
        <v-card-title v-else >
            <v-icon class="mr-1">mdi-clock-outline</v-icon>
            <span>{{ timeFormat(pedido.created_at) }}</span>
        </v-card-title>

        <OptionsList
            v-model="pedido"
            @onUpdateStatus="$emit('onUpdateStatus', $event)"
        />

        <v-divider/>

        <div style="position: absolute; top: -10px; right: 2px; display: flex;">
            <v-btn v-if="pedido.pronto" fab width="20" height="20" color="black" readonly>
                <v-icon small color="white">mdi-check</v-icon>
            </v-btn>
            <v-btn v-if="isImpresso(pedido)" fab width="20" height="20" color="black" readonly>
                <v-icon small color="white">mdi-printer</v-icon>
            </v-btn>
            <v-btn v-if="pedido.entregador_id" fab width="20" height="20" color="black" readonly>
                <v-icon small color="white">mdi-bike</v-icon>
            </v-btn>
             <v-btn v-if="hasPayment" fab width="20" height="20" color="black" readonly>
                <v-icon small color="white">mdi-currency-usd</v-icon>
            </v-btn>
        </div>

        <v-card-text align="center" justify="center" style="padding: 0 10px 14px 10px">
            <div 
                v-for="(produtos, index) in gerarInfoCard(pedido.produtos)" 
                :key="index"
                style="text-align: left; font-size: 12px;"
            >
                <b>{{produtos.quantidade}}x</b> {{produtos.descricao}}
            </div>
            <div v-if="pedido.produtos.length" style="line-height: 15px;">...</div>
            <v-chip class="ma-1" small outlined>
                <b>TOTAL R${{ getTotal(pedido) }}</b>
            </v-chip>
            <v-chip 
                v-if="getFormaPagamento(pedido.forma_pagamento)" 
                class="ma-1" 
                small 
                outlined
            >
                <b>{{ getFormaPagamento(pedido.forma_pagamento) }}</b>
            </v-chip>

            <v-menu
                v-if="opcoesTipoAtivo"
                offset-y
                open-on-hover
                close-on-content-click
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        small
                        rounded
                        depressed
                        outlined
                        color="white"
                        @click.stop=""
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-icon small class="mr-3">mdi-printer</v-icon> Imprimir
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item @click="imprimir({ pedido, tipo: 'resumido' })">
                        <v-list-item-title>Resumido</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="imprimir({ pedido, tipo: 'completo' })">
                        <v-list-item-title>Completo</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-btn
                v-else
                small
                rounded
                depressed
                outlined
                color="white"
                @click.stop="imprimir({pedido, tipo: 'completo'})"
            >
                <v-icon small class="mr-3">mdi-printer</v-icon> Imprimir
            </v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import OptionsList from './OptionsList';
import formasEntrega from '../../utils/formasEntrega';
import { subtotalGeral } from '@/utils/calc';

export default {
    name: 'Card',

    components: {
        OptionsList,
    },

    props: [
        'pedido', 
        'formasEntrega', 
        'formasPagamento', 
        'configuracoes',
    ],

    computed: {
        opcoesTipoAtivo() {
            return this.configuracoes?.impressao?.opcoesTipoAtivo;
        },

        hasPayment() {
            const totalPago = this.pedido.pagamentos.reduce((a, b) => a + (+b.valor), 0);
            return totalPago;
        },

        getClienteNome() {
            return this.pedido.cliente?.nome?.substr(0, 11) || '';
        },
    },

    methods: {
        isImpresso(pedido) {
            return pedido?.impresso || false;
        },

        gerarInfoCard(produtos) {
            return produtos.filter((v, i) => (i < 2));
        },

        timeFormat(date) {
            return date ? this.moment(date).format('HH:mm') : '';
        },

        agendamentoFormat(d) {
            if (!d) {
                return '';
            }

            if (d.length > 20) {
                const parts = d.split(' ');

                const date = this.moment(parts[0]);
                const formattedDate = date.format('DD/MM/YYYY') + ' ' + parts[1] + ' - ' + parts[3];

                return formattedDate;
            }

            return this.moment(d).format('DD/MM/YYYY');
        },

        getProgressBarColor(pedido) {
            if (pedido.entrega == formasEntrega.RETIRADA) {
                return 'pink lighten-1';
            }

            if (pedido.entrega == formasEntrega.DELIVERY) {
                return 'cyan';
            }

            if (pedido.entrega == formasEntrega.LOCAL) {
                return 'primary';
            }
        },

        cardColor(entrega) {
            let color = 'gray';
            (entrega == formasEntrega.LOCAL) && (color = '#001f3f');
            (entrega == formasEntrega.DELIVERY) && (color = '#0074D9');
            (entrega == formasEntrega.RETIRADA) && (color = '#85144b');
            return color;
        },

        getEntrega(entrega) {
            let text = '';
            (entrega == formasEntrega.LOCAL) && (text = 'Mesa');
            (entrega == formasEntrega.DELIVERY) && (text = 'Delivery');
            (entrega == formasEntrega.RETIRADA) && (text = 'Retirar no Balcão');
            return text;
        },

        imprimir(pedido) {
            this.$emit('imprimir', pedido);
        },

        getTotal(pedido) {
            let total = subtotalGeral(pedido.produtos);

            const valorFrete = this.pedido.entrega == 2 ? (+this.pedido.valor_frete || 0) : 0;

            total +=
                valorFrete
                + (+pedido.acrescimo || 0)
                - (+pedido.desconto || 0);

            return this.formatPrice(total);
        },

        getFormaPagamento(id) {
            if (!id) {
                return '';
            }
            const formaPagamento = this.formasPagamento.find(e => e.id === id)
            return formaPagamento.descricao;
        },
    }
}
</script>

<style scoped>
.class-on-data-table table {
    table-layout: fixed;
}

.v-card__text, .v-card__title {
    word-break: normal;
    /* maybe !important  */
}

.v-card__title {
    padding: 0 12px 0 12px;
    font-size: 15px;
    height: 28px !important;
}

.card-overlay {
    background-color: #000000db;
    height: 101%;
    width: 155px;
    position: absolute;
    z-index: 1;
}

.card-overlay-center {
    height: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes pulse {
   0% {
		transform: scale(0.93);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.93);
	}
}

.pulse {
    animation: pulse 2s infinite;
}
</style>
