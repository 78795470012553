<template>
    <v-dialog v-model="visible" persistent max-width="600px">
        <v-card>
            <v-card-title class="justify-center">
                <v-switch
                    dense
                    inset
                    hide-details
                    class="pb-2"
                    true-value="ativo"
                    false-value="inativo"
                    v-model="form.status"
                    :label="form.status === 'ativo' ? 'Ativo' : 'Inativo'"
                />
                <v-spacer/>
                <span class="text-h5">{{ form.id ? 'Edição' : 'Cadastro' }} de Município</span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text align="center" justify="center" style="height: 120px">
                <v-text-field
                    label="Nome"
                    v-model="form.descricao"
                    outlined
                    dense
                    class="mt-2"
                />
                <v-text-field
                    label="Taxa de entrega"
                    v-model="form.valor"
                    prefix="R$"
                    dense
                    outlined
                    hide-details
                    placeholder="0.00"
                    v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                />
            </v-card-text>
            <v-card-actions>
                <v-btn text color="warning" @click="$emit('close')">Cancelar</v-btn>
                <v-spacer />
                <v-btn color="success" @click="salvar" :loading="loading">
                    <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
    name: 'Municipio',

    props: {
        visible: {
            type: Boolean,
            default: false
        },
        formData: {
            type: Object,
            default: Object
        },
    },

    data: () => ({
        loading: false,
        form: {
            descricao: '',
            valor: '',
        },
    }),

    mounted() {
        this.form = this.formData;
    },

    watch: {
        formData(value) {
            this.form = value ? JSON.parse(JSON.stringify(value)) : {};
        }
    },

    methods: {
        ...mapMutations({
            setDashboardInitialized: 'setDashboardInitialized',
        }),

        salvar() {
            const id = this.form.id;

            if (!this.form.descricao) {
                this.notify('Informe o nome', 'warning');
                return;
            }

            if (id) {
                this.loading = true;
                this.$http.put(`municipios/${id}`, this.form).then(() => {
                    this.$emit('onCadastro');
                    this.notify('Atualizado com sucesso!');
                    this.setDashboardInitialized(false);
                }).catch(() => {
                    this.notify('Verifique sua conexão com a internet', 'warning');
                }).finally(() => (this.loading = false));
                return;
            }

            this.loading = true;
            this.$http.post('municipios', this.form).then(resp => {
                this.$emit('onCadastro', resp.data.data);
                this.notify('Salvo com sucesso!');
                this.setDashboardInitialized(false);
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        }
    }
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
