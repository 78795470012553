<template>
    <v-dialog :value="true" width="320">
        <v-card>
            <v-card-title>
                <span>Ordenação dos Pedidos</span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="mt-4" style="height: 130px;">
                <v-select
                    hide-details
                    :items="fields"
                    class="mt-2"
                    outlined
                    dense
                    v-model="sortField"
                />
                <v-select
                    hide-details
                    :items="order"
                    class="mt-4"
                    outlined
                    dense
                    v-model="sortOrder"
                />
            </v-card-text>
            <v-card-actions>
                <v-btn
                    @click="$emit('close')"
                    color="warning"
                    text
                >Cancelar</v-btn>
                <v-spacer/>
                <v-btn
                    @click="salvar"
                    color="success"
                    large
                >
                    <v-icon>mdi-content-save</v-icon>
                    Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'HomeConfig',

    props: ['value'],

    data: () => ({
        fields: [
            {
                value: 'codigo',
                text: 'Por código',
            },
            {
                value: 'prazo_entrega',
                text: 'Por tempo de entrega',
            },
        ],
        order: [
            {
                value: 'desc',
                text: 'Do maior ao menor',
            },
            {
                value: 'asc',
                text: 'Do menor ao maior',
            },
        ],
        sortField: 'codigo',
        sortOrder: 'desc',
    }),

    mounted() {
        this.sortField = localStorage.getItem('zm-dashboard-sort-field') || 'codigo';
        this.sortOrder = localStorage.getItem('zm-dashboard-sort-order') || 'desc';
    },

    methods: {
        salvar() {
            localStorage.setItem('zm-dashboard-sort-field', this.sortField);
            localStorage.setItem('zm-dashboard-sort-order', this.sortOrder);

            this.$emit('save');
        },
    },
}
</script>
