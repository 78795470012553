<template>
  <v-dialog
        v-model="visible"
        fullscreen
        hide-overlay
        scrollable
    >
        <v-card tile>
            <v-card-title dark>
                <v-text-field
                    v-model="pesquisaProduto"
                    ref="pesquisaProduto"
                    dense
                    outlined
                    clearable
                    hide-details
                    label="Pesquise o produto"
                />

                <v-spacer/>
                <v-toolbar-title>Adicionar Produtos</v-toolbar-title>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text style="display: flex;">
                <div :style="`display: flex; flex-direction: column; min-width: 220px; height: ${$vuetify.breakpoint.height - 170}px; overflow-x: auto;`">
                    <v-btn
                        v-for="grupo in produtosGrupos" :key="grupo.descricao"
                        outlined
                        small
                        height="40"
                        width="200"
                        :class="`ma-1 ${filtroByGrupo === grupo.descricao ? 'primary white--text' : ''}`"
                        @click="filtroByGrupo = grupo.descricao"
                    >
                        {{ grupo.descricao.slice(0, 22) }}
                    </v-btn>
                </div>
                <div class="flex-container" :style="`height: ${$vuetify.breakpoint.height - 170}px; overflow-x: auto;`">
                    <v-card
                        @click="produtoAdicionarQtd(item.produto_id, item.grupo.id)"
                        class="ma-2 pa-2 elevation-5"
                        width="350"
                        max-height="150"
                        v-for="(item, i) in getProdutos()"
                        :key="i"
                    >
                        <v-badge
                            v-if="item.quantidade > 0"
                            offset-x="0"
                            offset-y="20"
                            color="primary"
                            :content="item.quantidade"
                            style="position: absolute; z-index: 1;"
                        />

                        <v-btn
                            v-if="item.quantidade > 0"
                            color="error"
                            small
                            fab
                            @click.stop="produtoRemoverQtd(item.produto_id)"
                            style="position: absolute; right: 8px; z-index: 1;"
                        >
                            <v-icon>mdi-minus</v-icon>
                        </v-btn>

                        <div style="display: flex;">
                            <div style="width: 100px;">
                                <v-img
                                    :src="getUrl(item.imagem)"
                                    width="100"
                                    height="100"
                                />
                            </div>

                            <div>
                                <div class="product-name mt-2 ml-2">
                                    {{ item.descricao }}
                                </div>

                                <v-chip class="ma-2 white--text" color="green">
                                    R$ {{ formatPrice(item.valor) }}
                                </v-chip>
                                <v-chip
                                    v-if="isIndisponivel(item)"
                                    class="ma-2 white--text"
                                    color="error"
                                    small
                                >
                                    {{ isIndisponivel(item) }}
                                </v-chip>
                            </div>
                        </div>
                    </v-card>
                </div>
            </v-card-text>

            <v-card-actions>
                <div class="py-1 px-4" style="border: 1px solid #ddd; border-radius: 8px;">
                    <div class="caption">
                        SELECIONADO
                    </div>
                    <div style="font-size: 20px; font-weight: bold;">
                        R$ {{ formatPrice(totalSelecionado) }}
                    </div>
                </div>
                <v-spacer />
                <v-btn
                    @click="comandaAdicionar"
                    color="success"
                    large
                >
                    <v-icon>mdi-cart</v-icon>
                    <span>Adicionar</span>
                </v-btn>
            </v-card-actions>
        </v-card>

        <DialogSteps
            v-model="dialogSteps.item"
            :visible="dialogSteps.visible"
            @finish="onStepsFinish"
            @close="dialogSteps = { visible: false }"
        />
    </v-dialog>
</template>

<script>
import DialogSteps from '../DialogSteps';
import { clone } from '@/utils/utils';
import { mapState } from 'vuex';

export default {
    name: 'DialogAdicionarProdutos',

    components: {
        DialogSteps,
    },

    props: ['visible', 'value'],

    data: () => ({
        totalSelecionado: 0,
        pesquisaProduto: '',
        filtroByGrupo: '',
        dialogSteps: {
            visible: false,
            item: {},
        },
        headersProdutos: [
            {text: 'Qtd', value: 'quantidade', sortable: false, align: 'center'},
            {text: 'Item', value: 'descricao', sortable: false, align: 'start'}, // start center end
            {text: 'Valor', value: 'valor', sortable: false, align: 'center'},
            {text: '', value: 'acoes', sortable: false, align: 'center'},
        ],
    }),

    computed: {
        ...mapState([
            'produtos',
            'produtosGrupos',
            'configuracoes',
        ]),

        isFinalizado() {
            return this.value.finalizado;
        },
    },

    watch: {
        visible(v) {
            v && setTimeout(() => {
                this.$refs.pesquisaProduto.$refs.input.focus();
                this.updateTotalSelecionado();
            }, 100);

            !v && this.$emit('close');
        },
    },

    methods: {
        getProdutos() {
            let products = [];
            const search = this.pesquisaProduto?.toLowerCase();

            if (!search) {
               products = this.filtroByGrupo
                ? this.produtos.filter(produto => produto.grupo.descricao == this.filtroByGrupo)
                : this.produtos;

                // products.forEach((produto, index) => {
                //     produto.index = index + 1;
                //     return produto;
                // });

                return products;
            }

            products = this.produtos.filter(produto => produto.descricao.toLowerCase().includes(search));
            // products.forEach((produto, index) => {
            //     produto.index = index + 1;
            //     return produto;
            // });

            return products;
        },

        isIndisponivel(produto) {
            if (produto.status == 'inativo') {
                return 'inativo';
            }

            const outOfStock = this.configuracoes.controle_estoque && produto.controle_estoque && produto.estoque <= 0;
            return outOfStock ? 'sem estoque' : false;
        },

        async produtoAdicionarQtd(produto_id, grupo_id) {
            const produto = this.produtos.find(p => p.produto_id == produto_id && p.grupo.id == grupo_id);

            // const isIndisponivel = this.isIndisponivel(produto);
            // const msg = 'Produto indisponível, deseja adicionar mesmo assim?';
            // if (isIndisponivel && !await this.$root.$confirm('Confirmação', msg, { color: 'red darken-1' })) {
            //     return;
            // }

            let comboWithSteps = false;
            produto.produtos?.forEach(c => (c.passos?.length && (comboWithSteps = true)));
            const hasSteps = produto?.passos?.length || comboWithSteps;

            //TODO: ajustar para conseguir adicionar varios itens com etapas sem precisar adicionar um a um
            // poderia duplicar o item na listagem...
            if (hasSteps && produto.quantidade > 0) {
                this.notify('Item já escolhido, adicione na comanda antes de continuar', 'warning');
                return;
            }

            if (hasSteps) {
                this.dialogSteps = { visible: true, item: produto };
                return;
            }

            produto.quantidade++;

            this.updateTotalSelecionado();

            // this.atualizarTotais();
        },

        produtoRemoverQtd(id) {
            const find = this.produtos.find(d => id == d.produto_id && d.quantidade > 0);

            find && find.quantidade--;

            this.updateTotalSelecionado();

            // this.atualizarTotais();
        },

        onStepsFinish({produto_id, grupo, passos, produtos}) {
            this.dialogSteps = { visible: false, item: null };

            const grupo_id = grupo.id;
            const produto = this.produtos.find(p => p.produto_id == produto_id && p.grupo.id == grupo_id);

            // Combo
            if (produtos?.length) {
                produto.produtos.forEach((p, index) => {
                    if (!p.passos?.length) {
                        return;
                    }

                    p.__passos__ = produtos[index].passos;
                })
            }

            produto.__passos__ = passos;
            produto.quantidade++;

            // this.atualizarTotais();
        },

        getUrl(img) {
            if (!img?.url) {
                return require('@/assets/noimageavailable.png');
            }

            const url = img.tmp
                ? `${this.$urlAPI}/files/tmp/${img.url}`
                : `${this.$urlAPI}/storage/${img.url}`;

            return url;
        },

        comandaAdicionar() {
            const adicionar = i => {
                i = clone(i);
                i.passos = i.__passos__;

                i.produtos?.forEach(produto => {
                    produto.produto_id = clone(produto.produto_id || produto.id);
                    produto.id = null;
                });

                i.id = null;

                if (i.hasOwnProperty('produtos')) {
                    i.combo_id = clone(i.combo_id || i.produto_id);
                    i.produto_id = null;
                } else {
                    i.produto_id = clone(i.produto_id || i.produto_id);
                }

                // i.quantidade = i.quantidade || 1;
                // i.total = +subtotal(i);

                // let comboWithSteps = false;
                if (i?.produtos?.length) {
                    i.produtos.forEach(produto => {
                        produto.passos = produto.__passos__;
                        // produto.passos?.length && (comboWithSteps = true);
                    });
                }

                // const hasSteps = i.passos?.length || comboWithSteps;

                // let match = false;
                // this.value.produtos.forEach(c => {
                //     if (c.produto_id == i.produto_id && c.grupo_id == i.grupo_id && !hasSteps) {
                //         c.quantidade = +c.quantidade + +i.quantidade;
                //         match = true;
                //     }
                // });
                // !match && this.value.produtos.push(i);

                // Adequado para sempre lançar os produtos separadamente pela dialog de produtos
                this.value.produtos.push(i);


                this.value.produtos.forEach((c, index) => {
                    c.index = index;
                });
            };

            this.produtos.forEach(e => {
                (e.quantidade > 0) && adicionar(e);
                e.quantidade = 0;
            });

            this.$emit('close');
        },

        updateTotalSelecionado() {
            this.totalSelecionado = this.produtos.reduce((a, b) => a + (+b.quantidade * +b.valor || 0), 0);
        },
    },
}
</script>

<style scoped>
.flex-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: flex-start;
    padding: 0;
    margin: 0;
    list-style: none;
}

.product-name {
    font-size: 16px;
    font-weight: 500;
}
</style>