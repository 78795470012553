
<template>
    <div>
        <div v-for="(passo, i) in item.passos" :key="i" :class="`ml-${tab} mb-2`">
            <v-row>
                <v-col cols="12" style="text-transform: uppercase;">
                    {{passo.descricao}}
                </v-col>
            </v-row>
            <v-row
                v-for="(complemento, i2) in passo.complementos"
                :key="i2"
            >
                <v-col cols="12" class="ml-4 my-2">
                    <b>{{ getFracao(passo, complemento) }}</b> {{ complemento.descricao }}
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { fracao } from '@/utils/calc'

export default {
    name: 'StepsList',

    props: {
        item: {
            type: Object,
        },

        tab: {
            type: [ String, Number ],
            default: '0',
        },

        combo: {
            type: Boolean,
            default: false,
        }
    },

    methods: {
        getFracao(passo, complemento) {
            return fracao(passo, complemento);
        },
    },
}
</script>

<style scoped>
.row {
    margin-top: 2px;
}

.col {
    padding: 0 12px 0 12px;
}
</style>
