<template>
    <div class="paginate">
        <v-spacer/>
        <span v-show="value.amount">
            R$ {{ formatPrice(value.amount) }}
        </span>
        <v-spacer/>
        <v-btn
            v-if="show"
            class="mx-2"
            fab
            x-small
            color="primary"
            @click="prev()"
        >
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-text-field
            v-if="show"
            @blur="$emit('input', pagination)"
            @keypress="isNumber($event)"
            style="max-width: 60px"
            v-model="pagination.current_page"
            hide-details
            single-line
            type="tel"
            outlined
            dense
        />
        <v-btn
            v-if="show"
            class="mx-2"
            fab
            x-small
            color="primary"
            @click="next()"
        >
            <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
        <v-spacer/>
        <span>Página: {{ value.current_page }} / {{ value.last_page }}</span>
        <span class="ml-4">Registros: {{ value.total }}</span>
    </div>
</template>

<script>
export default {
    name: 'Paginate',

    data: () => ({
        pagination: {
            current_page: 1,
            last_page: 1,
            total: 0,
        },
    }),

    props: {
        value: {
            type: Object,
            required: true,
        },
    },

    computed: {
        show() {
            return this.pagination.last_page > 1;
        },
    },

    watch: {
       value: {
            deep: true,
            handler(v) {
                this.pagination = v;
            },
        },
        // pagination: {
        //     deep: true,
        //     handler(v) {
        //         console.log('pg change', v)
        //         this.$emit('input', this.pagination);
        //     }
        // },
    },

    methods: {
        prev() {
            if (this.pagination.current_page <= 1) {
                return;
            }

            this.pagination.current_page--;
            this.$emit('input', this.pagination);
        },

        next() {
            if (this.pagination.current_page >= this.pagination.last_page) {
                return;
            }

            this.pagination.current_page++;
            this.$emit('input', this.pagination);
        },

        isNumber(e = window.event) {
            const charCode = e.which || e.keyCode;

            (charCode == 13) && this.$emit('input', this.pagination);

            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                e.preventDefault();
            } else {
                return true;
            }
        },
    },
}
</script>

<style scoped>
.paginate {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}
</style>
