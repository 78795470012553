<template>
    <v-dialog v-model="visible" persistent max-width="700">
        <v-card>
            <v-card-title class="justify-center">
                Termos de Uso
            </v-card-title>
            <v-card-text 
                v-html="termos"
                :style="`height: ${$vuetify.breakpoint.height - 230}px; overflow-x: auto;`"
            />
            <div style="display: flex; justify-content: center;">
                <v-checkbox v-model="checkbox" hide-details>
                    <template v-slot:label>
                        <div>Concordo com os Termos</div>
                    </template>
                </v-checkbox>
            </div>
            <v-card-actions>
                <v-btn @click="$emit('close')" text color="gray">Sair</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="aceitar" color="primary" large width="200">
                    ACEITAR
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'TermosUso',

    props: {
        visible: {
            type: Boolean,
            default: false
        },
    },

    data: () => ({
        termos: '',
        checkbox: false,
    }),

    watch: {
        visible(v) {
            v && this.consultar();
        }
    },

    mounted() {
        //
    },

    methods: {
        aceitar() {
            if (!this.checkbox) {
                this.notify('É necessário concordar com termos', 'warning');
                return;
            }

            alert('OK');
        },

        logout() {
            //
        },

        async consultar() {
            this.termos = (await this.$http.get('termos-de-uso')).data;
        }
    }
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}

.v-card__text {
    text-align: left !important;
}
</style>
