<template>
    <v-dialog v-model="visible" max-width="600px">
        <v-card>
            <v-card-title class="mb-6">
                <span>Observação</span>
                <v-spacer/>
                <v-btn icon @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-textarea
                    ref="observacao"
                    clearable
                    clear-icon="mdi-backspace-outline"
                    v-model="value.text"
                    placeholder="Observação..."
                    outlined
                    rows="3"
                />
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="warning"
                    text
                    @click="$emit('close')"
                >
                    Cancelar
                </v-btn>
                <v-spacer/>
                <v-btn
                    @click="salvar"
                    color="success"
                    large
                >
                    <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'DialogCliente',

    props: ['visible', 'value'],

    data: () => ({
        form: {},
    }),

    watch: {
        visible(v) {
            v && setTimeout(() => this.$refs.observacao.$refs.input.focus(), 100);

            !v && this.$emit('close');
        },
    },

    methods: {
        salvar() {
            this.$emit('save', this.value);
        },
    },
}
</script>
